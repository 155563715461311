<template>
    <div style="position: relative">
        <!-- <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" v-model="searchKey" @input='handleInput'></el-input>
        <div class="a-mt-10 list-box">
            <div class="a-flex-rsbc a-fs-14 a-fw-500 list-item a-plr-10" style="line-height: 40px" v-for="(item,index) in list" :key="index">
                <span>{{ item.label }}</span>
                <i class="el-icon-check a-c-blue a-fw-700"></i>
            </div>
        </div> -->
        <el-popover
            @hide='handleHidePopover'
            placement="bottom-start"
            :width="width"
            trigger="click">
            <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" v-model="searchKey" @input='handleInput'></el-input>
            <div class="a-mt-10 list-box">
                <div class="a-flex-rsbc a-fs-14 a-fw-500 list-item a-plr-10" style="line-height: 40px" v-for="(item,index) in list" :key="index" @click="handleChoose(item, index)">
                    <span :class="item.isCheck
                            ?'a-c-blue'
                            :item.disable?'a-c-place':'a-c-333'">{{ item.label }}</span>
                    <i class="el-icon-check a-c-blue a-fw-700" v-if="item.isCheck"></i>
                </div>
            </div>
            <el-button slot="reference" plain size='mini' icon="el-icon-plus">选择</el-button>
        </el-popover>
    </div>
</template>

<script>
export default {
    data () {
        return {
            searchKey: '',
            list: [],
            debounceTimeout: null
        }
    },
    props: {
        width: {
            default: 400
        },
        value: {
            required: true,
        },
        options: {
            type: Object,
            required: true,
            validator: (param) => {
                if (!param.url) {
                    console.log("le-select-remote-search 参数缺少url");
                    return false;
                }
                if (!param.method) {
                    console.log("le-select-remote-search 参数缺少method(post|get)");
                    return false;
                }
                if (!param.params) {
                    console.log("le-select-remote-search 参数缺少params");
                    return false;
                }
                if (!param.labelKey) {
                    console.log("le-select-remote-search 参数缺少labelKey");
                    return false;
                }
                if (!param.valueKey) {
                    console.log("le-select-remote-search 参数缺少valueKey");
                    return false;
                }
                if (!param.searchKey) {
                    console.log("le-select-remote-search 参数缺少searchKey");
                    return false;
                }
                return true;
            },
        },
        defaultOptions: { //默认待选项，数据复现时使用
            default: ()=>{
                return []
            }
        },
        disableOptions: {
            default: ()=>{
                return []
            }
        },
    },
    methods: {
        handleHidePopover () {
            this.list = []
            this.searchKey = ''
        },
        handleChoose (data, index) {
            if(data.disable && !data.isCheck) {
                return
            }
            let targetIndex = this.value.findIndex(res=>{
                return res.value == data[this.options.valueKey]
            })
            if(targetIndex != -1) {
                let arr = [...this.value]
                arr.splice(targetIndex,1)
                this.$emit('input', arr)
                this.$set(this.list, index, {
                    ...data,
                    isCheck: false,
                    disable: false
                })
            }else {
                let arr = [...this.value]
                arr.push({
                    ...data,
                })
                this.$set(this.list, index, {
                    ...data,
                    isCheck: true,
                    disable: false
                })
                this.$emit('input', arr)
            }
        },
        handleInput () {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.handlerSearch()
            }, 300);
        },
        handlerSearch() {
            let func = this.$Axios._post;
            if (this.options.method.toLowerCase() == "get") {
                func = this.$Axios._get;
            }
            let params = {
                ...this.options.params,
                pageNum: 1,
                pageSize: this.options.pageSize || 100,
            };
            params[this.options.searchKey] = this.searchKey;
            func({
                ...this.options,
                showLoading: false,
                params: params,
            }).then(res => {
                let arr = res.result.data.list?res.result.data.list:res.result.data
                this.list = arr.map((item) => {
                    let targetInfo = this.value.find(ite=>{
                        return ite.value == item[this.options.valueKey]
                    })
                    return {
                        ...item,
                        label: item[this.options.labelKey],
                        value: item[this.options.valueKey],
                        isCheck: targetInfo ? true : false,
                        disable: this.disableOptions.indexOf(item[this.options.valueKey]) != -1 ? true : false
                    };
                });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .list-box{
        max-height: 300px;
        overflow-y: auto;
        
        // position: absolute;
        // top: 50px;
        // left: 0;
        // width: 400px;
    }
    .list-box::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
    }
    .list-item:hover{
        background: #F5F7FA;
    }
</style>